import React from "react"
import { Row, Col, Image } from "react-bootstrap"

const Card = ({ img, title, desc }) => (
  <Row
    style={{
      backgroundColor: "#dfe1e1",
      margin: "0 15px",
      padding: "24px 10px",
    }}
  >
    <Col sm={12} md={12} style={{ textAlign: "center" }}>
      <Image
        style={{
          width: "2.5rem",
          height: "2.5rem",
          borderRadius: "50%",
          marginBottom: 20,
        }}
        src={img}
      />
    </Col>
    <Col sm={12} md={12} style={{ textAlign: "center" }}>
      <h4>{title}</h4>
    </Col>
    <Col sm={12} md={12} style={{ textIndent: "2em" }}>
      {desc}
    </Col>
    <Col sm={12} md={12} style={{ paddingTop: 10, textAlign: "center" }}>
      <Image style={{ transform: 'rotate(-90deg)', width: '1.rem', height: '1rem' }} src="http://cdn.jerryshi.com/gtkj/20200109111353.png" />
    </Col>
  </Row>
)

export default Card
