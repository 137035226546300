import React from "react"
import styled from "styled-components"
import SEO from "../../components/seo"
import Layout from "../../layouts/layout"
import Banner from "../../components/banner"
import Wrapper from "../../components/wrapper"
import style from "../../assets/global-style"
import { Col, Row, Image } from "react-bootstrap"
import gt1Icon from "../../images/gt-icon1.png"
import gt2Icon from "../../images/gt-icon2.png"
import gt3Icon from "../../images/gt-icon3.png"
import gt4Icon from "../../images/gt-icon4.png"
import MarketDescCard from "../../components/market-desc-card"

const Container = styled.div`
  margin-bottom: 14rem;

  .section {
    margin: 0 auto;
    max-width: 1200px;
    height: 24rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;

    img {
      width: 8rem;
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    .title {
      font-size: 2.2rem;
      letter-spacing: 5px;
      color: #555;
    }

    .desc {
      border: 1px solid #ccc;
      margin: 10px 20px;
      overflow-y: scroll;
      height: 60%;
      padding: 25px;
      letter-spacing: 1.6px;
      text-indent: 2em;

      font-family: "source-han-serif-sc";

      &:hover {
        ::-webkit-scrollbar-thumb {
          background-color: ${style["theme-color"]};
        }
      }

      &::-webkit-scrollbar {
        width: 16px;
        background-color: #dfe0e2;
        padding: 10px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        transition: background-color 2s;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: ${style["theme-color"]};
      }
    }
  }

  .business {
    margin: 0 auto;
    max-width: 1200px;
    margin-top: 30px;

    .header {
      .row {
        padding: 0 20px;
      }

      .col > div {
        text-align: center;
        height: 50px;
        line-height: 50px;
        background-color: #0a09a0;
        color: white;
        font-size: 24px;
        letter-spacing: 4px;
      }

      .col > div:hover {
        background-color: ${style["theme-color"]};
      }
    }
    .content {
      margin-top: 30px;
      min-height: 20rem;
      width: 100%;
      ${style.flexBox()}
    }

    @media screen and (max-width: 768px) {
      .header {
        .row {
          padding: 0 5px;
        }
        .col > div {
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }
`

const data = [
  {
    img: gt1Icon,
    title: "电梯安装维保",
    desc:
      "城镇化、老龄化推动传统建筑进行电梯更新与加装，电梯成为新建住宅建筑的标配，预计2023年中国电梯保有量突破千万台(目前627.83万台)...",
  },
  {
    img: gt2Icon,
    title: "智能化工程",
    desc:
      "用互联网进行交互生活服务需求已成为习惯，有上线下多渠道提供物业的需求每年约3%(平均改造周期30年)的住宅及6%(平均改造周期15年)的工程...",
  },
  {
    img: gt3Icon,
    title: "消防电机工程",
    desc:
      "电梯维保服务打包销售，消防系统为物联网建筑安全管理的首位，悦居生活平台全端上线需进行改进和完升级开发完升级开发完升级开发完升级开发...",
  },
  {
    img: gt4Icon,
    title: "软件开发",
    desc:
      "国家在大力发展智慧城市的建设，智慧设局是智慧城市的入口和终端。互联网+各行各业的新模式已成熟，物业公司用APP平台进行管理服务已成为标配...",
  },
]

const banners = [
  <Image src="http://cdn.jerryshi.com/gtkj/20200107111710.png" fluid />,
  <Image src="http://cdn.jerryshi.com/gtkj/20200107111711.png" fluid />,
  <Image src="http://cdn.jerryshi.com/gtkj/20200107111712.png" fluid />,
  <div style={{ maxWidth: 1200, margin: "10px auto", marginTop: 40 }}>
    <Row>
      {data.map(i => (
        <Col key={i.title} md={3} sx={12} style={{ padding: 10 }}>
          <MarketDescCard {...i} />
        </Col>
      ))}
    </Row>
  </div>,
]

const Page = () => {
  const [index, set] = React.useState(0)

  return (
    <Layout>
      <SEO title="格泰科技" />
      <Banner img="http://cdn.jerryshi.com/gtkj/20200107145200.jpg" />
      <Wrapper>
        <Container>
          <div className="section">
            <img
              className="logo"
              src="http://cdn.jerryshi.com/gtkj/20200106173144.png"
              alt="logo"
            />
            <span className="title">云南格泰科技有限公司</span>
            <div className="desc">
              <p>
                云南格泰科技有限公司前身为云南格泰电梯维修有限公司公司，成立于2013年4月，注册资本250万元，位于昆明市西山区梁源小区综合楼，由城建股份集团公司、城建物业集团公司全资控股，办公场地500平方米是专业从事电梯及配件销售、电梯维修保养、智能化小区、智能安防系统、弱电综合布线、智能楼宇对讲、智能数字监控、智能停车场管理系统、智能一卡通、智能公共广播、集销售、维修、生产、经营为一体的公司。
              </p>
              <p>
                云南格泰科技有限公司是一家专业从事智能建筑、软件开发、网络工程、电梯维保、消防系统维护集研发、设计、销售和服务的高科技公司，公司始终以敏锐的市场触觉和雄厚的技术实力、强大的技术能力，致力于不断设计适用于各形态建筑群的智能化系统、网络工程及服务类软件。
              </p>
              <p>
                公司目前的主营业务有：智能建筑设计施工、计算机软件开发、网络工程设计开发、电梯维修保养、电梯及电梯配件销售、消防系统维护、电力工程设计施工、计算机图文设计。
              </p>
              <p>
                公司从事电梯维保工作4年现有维保量为438台电梯，实际案例如下：湖畔之梦小区128台电梯、云南映象小区202台电梯、财富中心36台电梯（其中包含10台扶梯及10台无机房电梯）、城建大厦3台电梯
                、环球金融大厦7台（其中包含4台乘客电梯、7台车载电梯）、白塔花园电梯11台、澄江湖畔圣水29台。
              </p>
              <p>
                公司10年目标：成为云南省最具价值的智能建筑、软件开发、网络工程开发设计、电梯维修技术、消防系统维护一站式服务商。
              </p>
              <p>
                公司在发展和团队建设上有着公司的既定目标。现在公司的发展壮大靠决策者的指挥、靠人才的支撑、靠员工和领导一起努力，上下同欲，企业才会做大做强。同样个人价值的体现也要靠一个适合的环境，公司会珍视每一位员工的付出，让团队成员的人生价值和公司的发展融合在一起。
              </p>
            </div>
          </div>
          <div className="business">
            <div className="header">
              <Row>
                {["企业文化", "现有业务", "资源能力", "市场潜力"].map(
                  (i, idx) => (
                    <Col key={idx} onClick={() => set(idx)}>
                      <div
                        style={{
                          backgroundColor: index === idx ? "#B19460" : "",
                        }}
                      >
                        {i}
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
            <div className="content">{banners[index]}</div>
          </div>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Page
